import { WRPost } from '@utils/api/server';
import { WP_INTERVIEWS_ID, WP_PODCASTS_ID } from 'enums/wordpress';
import { ArticleI, ArticleType } from 'types/article';

export const getArticleType = (article: ArticleI | WRPost): ArticleType => {
  const categoryIds =
    article.categories?.map((category) => (typeof category === 'number' ? category : category.id)) || [];

  if (categoryIds.includes(WP_INTERVIEWS_ID[process.env.NEXT_PUBLIC_ENV || 'production']['pl'])) {
    return 'interview';
  }

  if (categoryIds.includes(WP_PODCASTS_ID[process.env.NEXT_PUBLIC_ENV || 'production']['pl'])) {
    return 'podcast';
  }

  if (article.type === 'videos') {
    return 'video';
  }

  return 'article';
};

export type ArticleVariety = 'redaction' | 'sponsored' | 'offer' | 'pitd';
export type ArticleVarieties = {
  locale: string;
  varieties: Array<ArticleVariety>;
  redactionsCount: number;
  sponsoredCount: number;
  //offerCount: number;
  //pitdCount: number;
};

export const getArticleVarieties = (articles: Array<ArticleI | WRPost>): ArticleVarieties => {
  const varieties: Array<ArticleVariety> = [];
  let redactionsCount = 0;
  let sponsoredCount = 0;
  //let offerCount = 0;
  //let pitdCount = 0;
  let locale = '';

  articles.forEach((article) => {
    locale = typeof article === 'object' && 'langName' in article ? article.langName : article.lang_name;
    const sponsoredFrom = typeof article === 'object' && 'sponsoredFrom' in article ? article.sponsoredFrom : typeof article === 'object' && 'sponsored_from_gmt' in article ? article.sponsored_from_gmt : null;
    const sponsoredTo = typeof article === 'object' && 'sponsoredTo' in article ? article.sponsoredTo : typeof article === 'object' && 'sponsored_to_gmt' in article ? article.sponsored_to_gmt : null;
    if (sponsoredFrom && sponsoredTo) {
      sponsoredCount++;
      varieties.push('sponsored');
    } else {
      redactionsCount++;
      varieties.push('redaction');
    }
  });

  return {
    locale,
    varieties,
    redactionsCount,
    sponsoredCount,
    //offerCount,
    //pitdCount,
  };
};
